import { useState } from 'react';
import { Link } from 'react-router-dom';

//import './Nav.css';

const Nav = () => {
  return(
    <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
      <div class="uppercase text-gray-800 dark:text-white font-black text-3xl">
        <Link to="/">
          MeshyData
        </Link>
      </div>
      <div class="block lg:hidden">
        <button class="flex flex-col ml-4">
          <span class="w-6 h-1 bg-gray-800 dark:bg-white mb-1"></span>
          <span class="w-6 h-1 bg-gray-800 dark:bg-white mb-1"></span>
          <span class="w-6 h-1 bg-gray-800 dark:bg-white mb-1"></span>
        </button>
      </div>
      <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div class="lg:flex-grow"></div>
        <div class="ml-4 mr-24 mt-8 text-gray-800 dark:text-white uppercase text-lg">
          <Link to="/pricing">
            Pricing
          </Link>
        </div>
        <div class="ml-4 mt-8">
          <a href="https://app.meshydata.com/" class="uppercase py-2 px-4 rounded-lg border-2 border-transparent bg-gray-600 text-white text-lg hover:bg-gray-700">
            Login 
          </a>
        </div>
        <div class="ml-4 mt-8">
          <a href="https://app.meshydata.com/onboarding" class="uppercase py-2 px-4 rounded-lg bg-pink-600 border-2 border-transparent text-white text-lg hover:bg-pink-700">
            Sign up    
          </a>
        </div>
      </div>
    </nav>
  )
  return(
    <div class="h-24 sm:h-32 flex items-center z-30 w-full">
      <div class="container mx-auto px-6 flex items-center justify-between">
        <div class="uppercase text-gray-800 dark:text-white font-black text-3xl">
          <Link to="/">
            MeshyData
          </Link>
        </div>
        <div class="font-sen text-gray-800 dark:text-white uppercase text-lg md:flex items-center hidden">
          <Link to="/pricing" class="py-2 px-6 flex">
            Pricing
          </Link>
          <a href="https://app.meshydata.com/" class="ml-10 uppercase py-2 px-4 rounded-lg border-2 border-transparent bg-gray-600 text-white text-lg hover:bg-gray-700">
            Login 
          </a>
          <a href="https://app.meshydata.com/onboarding" class="ml-4 uppercase py-2 px-4 rounded-lg bg-pink-600 border-2 border-transparent text-white text-lg hover:bg-pink-700">
            Sign up    
          </a>
        </div>
      </div>
    </div>
  );
};
export default Nav;
