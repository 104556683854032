import './App.css';
import AppRoutes from './AppRoutes';

const App = () => {
  return (
    <html class="dark">
      <body class="dark:bg-gray-800 bg-white relative">
        <AppRoutes/>
      </body>
    </html>
  );
}
export default App;
