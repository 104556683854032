//import './UseCases.css';

const UseCases = () => {
  return(
    <div class="container mt-32 mx-auto px-6 p-6 bg-white dark:bg-gray-800">
        <div class="mb-16 text-center">
            <h2 class="text-base text-indigo-500 font-semibold tracking-wide uppercase">
                Use Cases 
            </h2>
            <p class="mt-2 uppercase text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
               Unify all the data, and<br/> collaborate
            </p>
        </div>
        <div class="flex flex-wrap my-12 dark:text-white">
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        B2B: Enterprise Teams and Domains 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    Endless tools, so much configuration, managing permissions, deployment, and on and on... <p/><br/>  All of which is "secondary" work, when what you and your team really want to do is the "primary" work of analyzing your data, integrating with partners, automating your systems, experimentally training your models, providing high quality visualizations, etc...<p/><br/> Get back to the enjoyable aspects of data analysis with MeshyData!
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        B2C: Citizen Data Analyst
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    Anyone can unify and control their own personal data with the power of MeshyData. <p/><br/>Just imagine what you can do with the power of a modern data analytics stack applied to your own data. <p/><br/> Free yourself from legacy apps that have too long held your data captive. Join data from a variety of similar sources to gain new insights.  Experiment by creating meta apps on top of your own data.
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        C2C: Internet-scale Data Collaboration
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    Have you ever thought of an interesting data analysis project, but then felt overwhelmed with what it would take to scrape the web, clean the data, implement the ETL, load the data, perform the analysis, and serve the results (not to mention automating all of that)? <p/><br/> What if you could crowdsource the effort and share these data engineering tasks with tens of thousands of others? <p/><br/> It's now possible - with MeshyData!
                </p>
            </div>
        </div>
    </div>
  );
};
export default UseCases;
