//import './UseCases.css';
import Nav from '../components/Nav';
import PageCTA from '../components/PageCTA';
import Footer from '../components/Footer';

const UseCases = () => {
  return(
    <>
      <Nav/>
      <main class="bg-white relative overflow-hidden h-screen">
        <div class="bg-white flex relative z-20 items-center">
            <div class="container mx-auto px-6 flex flex-col justify-between items-center relative py-8">
                <div class="flex flex-col">
                    <h1 class="font-light w-full uppercase text-center text-4xl sm:text-5xl text-gray-800">
                      Each Team Member Benefits from DataRich 
                    </h1>
                    <h2 class="font-light max-w-2xl mx-auto w-full text-xl text-gray-500 text-center py-8">
                        DataRich Enterprise gives you the best data experience with all the features you need for unlocking the value of your digital assets. Let us take care of the configuration decisions, while you get back to adding value to your business.
                    </h2>
                </div>
            </div>
        </div>
        <section>
            <div class="container max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white">
                <div class="flex flex-wrap -mx-8">
                    <div class="w-full lg:w-1/2 px-8">
                        <div class="mb-12 lg:mb-0 pb-12 lg:pb-0 border-b lg:border-b-0">
                            <h2 class="mb-4 text-3xl lg:text-4xl font-bold font-heading">
                                Sed ac magna sit amet risus tristique interdum, at vel velit in hac habitasse platea dictumst.
                            </h2>
                            <p class="mb-8 leading-loose text-gray-500">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, quam nec venenatis lobortis, mi risus tempus nulla, sed porttitor est nibh at nulla. Praesent placerat enim ut ex tincidunt vehicula. Fusce sit amet dui tellus.
                            </p>
                            <div class="w-full md:w-1/3">
                                <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                    Schedule a Demo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 px-8">
                        <ul class="space-y-12">
                            <li class="flex -mx-4">
                                <div class="px-4">
                                    <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                        1
                                    </span>
                                </div>
                                <div class="px-4">
                                    <h3 class="my-4 text-xl font-semibold">
                                        Responsive Elements
                                    </h3>
                                    <p class="text-gray-500 leading-loose">
                                        All elements are responsive and provide the best display in all screen size. It&#x27;s magic !
                                    </p>
                                </div>
                            </li>
                            <li class="flex -mx-4">
                                <div class="px-4">
                                    <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                        2
                                    </span>
                                </div>
                                <div class="px-4">
                                    <h3 class="my-4 text-xl font-semibold">
                                        Flexible Team
                                    </h3>
                                    <p class="text-gray-500 leading-loose">
                                        Flexibility is the key. All team is available 24/24 and joinable every day on our hotline.
                                    </p>
                                </div>
                            </li>
                            <li class="flex -mx-4">
                                <div class="px-4">
                                    <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                        3
                                    </span>
                                </div>
                                <div class="px-4">
                                    <h3 class="my-4 text-xl font-semibold">
                                        Ecologic Software
                                    </h3>
                                    <p class="text-gray-500 leading-loose">
                                        Our Software are ecologic and responsable. Green is not just a color, it&#x27;s a way of life.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <PageCTA/>
      <Footer/>
    </>
  );
};
export default UseCases;
