//import './Features.css';

const Features = () => {
  return(
    <div class="container mt-32 mx-auto px-6 p-6 bg-white dark:bg-gray-800">
        <div class="mb-16 text-center">
            <h2 class="text-base text-indigo-500 font-semibold tracking-wide uppercase">
                Features
            </h2>
            <p class="mt-2 uppercase text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                A modern data platform, in your own cloud,<br/> with no config, but also no lock-in  
            </p>
        </div>
        <div class="flex flex-wrap my-12 dark:text-white">
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Unified Metadata Management 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Data Privacy First
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Modern Data Platform 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Commodity Cloud Services 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                       No-Config 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        No Vendor Lock-in 
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Serverless
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Scalable
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-8">
                <div class="flex items-center mb-6">
                    <svg width="20" height="20" fill="currentColor" class="h-6 w-6 text-indigo-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                        </path>
                    </svg>
                    <div class="ml-4 text-xl">
                        Collaborative
                    </div>
                </div>
                <p class="leading-loose text-gray-500 dark:text-gray-200 text-md">
                    {/*TODO*/}
                </p>
            </div>
        </div>
    </div>
  );
};
export default Features;
