//import './Home.css';
import Nav from '../components/Nav';
import Hero from '../components/Hero';
import UseCases from '../components/UseCases';
import Features from '../components/Features';
//import FAQs from '../components/FAQs';
import Footer from '../components/Footer';

const Home = () => {
  return(
    <>
      <Nav/>
      <Hero/>
      <UseCases/>
      <Features/>
      {/*<FAQs/>*/}
      <Footer/>
    </>
  );
};
export default Home;
