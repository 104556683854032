import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Platform from './pages/Platform';
import UseCases from './pages/UseCases';
import Resources from './pages/Resources';

const AppRoutes = () => {
  return(
    <Router>
      <Switch>
        <Route path="/platform">
          <Platform/>
        </Route>
        <Route path="/use-cases">
          <UseCases/>
        </Route>
        <Route path="/resources">
          <Resources/>
        </Route>
        <Route path="*">
          <Home/>
        </Route>
      </Switch>
    </Router>
  );
};
export default AppRoutes;
