import './Hero.css';

//const Hero = () => {
function Hero() {
  return (
    <div class="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
      <div class="container mx-auto px-6 flex relative py-16">
          <div class="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
              <span class="w-20 h-2 bg-gray-800 dark:bg-white mb-12">
              </span>
              <div class="sm:hidden sm:block sm:w-1/3 lg:w-3/5 relative">
                <img src="/images/order_chaosA9_v2.png" class="max-w-lg md:max-w-xl m-auto"/>
              </div>
              <div class="w-80 sm:w-auto">
                <h1 class="font-bebas-neue uppercase text-3xl sm:text-5lg font-black flex flex-col leading-none dark:text-white text-gray-800 mt-12">
                    Your unifying, collaborative
                    <span class="text-6xl sm:text-8lg">
                    data platform
                    </span>
                </h1>
              </div>
              <div class="w-80 sm:w-auto">
                <p class="mt-4 text-xl sm:text-xl text-gray-700 dark:text-white">
                  A scalable, serverless, no-config data integration platform unified by your metadata and your trusted collaborators
                </p>
              </div>
              <div class="flex mt-8">
                  <a href="https://app.meshydata.com/onboarding" class="uppercase py-2 px-4 rounded-lg bg-pink-600 border-2 border-transparent text-white text-lg mr-4 hover:bg-pink-700">
                    Get started
                  </a>
              </div>
          </div>
          <div class="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
            <img src="/images/order_chaosA9_v2.png" class="max-w-lg md:max-w-xl m-auto"/>
          </div>
      </div>
    </div>
  );
};
export default Hero;
