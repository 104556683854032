//import './PageCTA.css';

const PageCTA = () => {
  return(
    <div class="bg-white dark:bg-gray-800 ">
        <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                <span class="block">
                  Ready to be data rich?
                </span>
                <span class="block text-indigo-500">
                  value on Day 1
                </span>
            </h2>
            <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
              Our low-friction onboarding process only takes a few minutes, and then you and your team will have a state of the art data architecture to leverage immediately
            </p>
            <div class="lg:mt-0 lg:flex-shrink-0">
                <div class="mt-12 inline-flex rounded-md shadow">
                  <a href="https://app.meshydata.com/onboarding" class="uppercase py-2 px-4 rounded-lg bg-pink-600 border-2 border-transparent text-white text-lg mr-4 hover:bg-pink-700">
                      Get started
                  </a>
                </div>
            </div>
        </div>
    </div>
  );
};
export default PageCTA;
